import { useEffect, useRef, useState } from "react"
import PropTypes from 'prop-types';
import { Modal } from 'bootstrap';



const SuccessPopup = function ({ title, message, isError, active, delay, closed }) {


    const toggleModal = ((value) => {

        const myModal = new Modal(document.getElementById("success-popup"), {});
        if (value) {
            myModal.show();
        } else {
            myModal.hide();
        }
    });
    useEffect(() => {
        var myModalEl = document.getElementById('success-popup');
        if (myModalEl) {

            myModalEl.addEventListener('hide.bs.modal', function (event) {
                document.querySelectorAll('.modal-backdrop.show').forEach(el => el.remove());
                closed();
            });
        }
    }, []);

    useEffect(() => {
        toggleModal(active);
    }, [active])

    return (


        <div className="default-modal modal fade changePassword2" id="success-popup" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content p-0">
                    <div className="head-green">
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" className="fa fa-times"/>
                        </button>
                        <div className="check_icon">
                            <div id="pucheck_icon" />
                        </div>
                    </div>
                    <div className="modal-body py-0 row">
                        <div className="col-md-12 py-4 text-center">
                            <h6>{!isError ? "Success" : "Error"}</h6>
                            <p className="mb-0">{message ? message : ""}</p>
                        </div>
                        <div className="col-md-12 button text-center mb-5">
                            {/* <a className="btn_darkbluep mt-0 d-inline-block px-5 me-1">Ok</a> */}
                            <button className="btn_darkbluep mt-0 d-inline-block px-5 me-1" data-bs-dismiss="modal" aria-label="Close">ok</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

















        //     <div className="default-modal modal fade profile_Updated" id="success-popup" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        //     <div className="modal-dialog modal-dialog-centered" role="document">
        //         <div className="modal-content p-4">
        //                  {/* <div className="modal-header"> 
        //                       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
        //                       </button>
        //                  </div>  */}
        //             <div className="rounded mx-auto d-block">
        //                 {
        //                     <img src={!isError ? "images/pcheck_icon.png" : 'images/cancel.png'}  className='"rounded mx-auto d-block"'/>
        //                 }
        //                 {/* <img src="../../images/pcheck_icon.png" /> */}
        //             </div>
        //             <div className="modal-body py-0 row">
        //                 <div className="col-md-12 py-4 text-center">
        //                     <p className="mb-0">{message ? message : ""}</p>
        //                 </div>
        //                 <div className="col-md-12 button text-center ">
        //                     <button className="btn btn-danger mt-0 d-inline-block px-5 ml-1" data-bs-dismiss="modal" aria-label="Close">ok</button>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

        //         <>
        //             <div className="default-modal modal fade logout" id="success-popup" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        //                 <div className="modal-dialog " role="document">
        //                     <div className="modal-content">
        //                         {/* <div className="modal-header"> */}
        //                             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        // {/*  */}
        //                         {/* </div> */}
        //                         <div className="rounded mx-auto d-block p-2">
        //                             {
        //                                 <img className="image-fluid" src={!isError ? "images/pcheck_icon.png" : 'images/cancel.png'} />
        //                             }
        //                             {/* <img src="../../images/pcheck_icon.png" /> */}
        //                         </div>
        //                         <div className="modal-body py-0 row">
        //                             <div className="col-md-12 py-4 text-center">
        //                                 <p className="mb-0">{message ? message : ""}</p>
        //                             </div>
        //                             <div className="col-md-12 button text-center mb-5">
        //                                 <button type="submit" className="btn btn-danger mt-0 d-inline-block px-5 ml-1 " data-bs-dismiss="modal" aria-label="Close">ok</button>
        //                             </div>

        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>



        //         </>
    )
}

SuccessPopup.propTypes = {


}

export default SuccessPopup

import React, { Children, useState } from 'react'
import PropTypes from 'prop-types';
import { debounce } from "lodash";
import { useSearchParams } from 'react-router-dom';

function TableSearch({ filterValues, onFilterChange,onStatusChange, onSearch, onDateChange, children }) {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [search, setSearch] = useState("")

  const handleSearch = debounce((value) => {
    onSearch(value);
  }, 500);
  function handleSubmit(event) {
    // event.preventDefault();
  }
  const onReset = () => {
    window.location.reload()
  }

  return (
    <>
      <div className="row mb-4">
        <div className="col-md-6">
          
       
              <div className="d-flex align-items-center">
{children}
                {/* <label htmlFor className="siteLabel p-0 pe-3">Sort by:</label>
                <div className="position-relative">
                  <select className="w-300" onChange={e=>onFilterChange({status:e.target.value})}>
                    {
                      filterValues?.map((value,valueIndex) => (
                        <option key={valueIndex}>{value}</option>

                      ))
                    }
                  </select>
              </div> */}
                </div>
              

                </div>
                
        <div className="col-md-6">
            {onStatusChange!== undefined ?
          <div className="d-flex align-items-center justify-content-end">
            <label htmlFor className="siteLabel p-0 pe-3">Filter by Status:</label>
            <div className="position-relative">
              <select className="w-300" onChange={(e)=>onStatusChange({status:e.target.value})}>
            {
              filterValues?.map((value,valueIndex)=>(

                <option key={valueIndex} value={value.id}>{value.label}</option>
              ))
            }
            </select>
            </div>
        </div>
          :""
        }
              
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-6">
          {/* <div className="d-flex align-items-center">
            <label htmlFor className="siteLabel p-0 pe-3">Filter By Category:</label>
            <div className="position-relative">
              <select className="w-300">
                <option>Quote Title</option>
                <option>Quote Title</option>
                <option>Quote Title</option>
              </select>
            </div>
          </div> */}
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center justify-content-end">
            <form className="serchbarHead w-300">
              <input type="email" name placeholder="Search...." onChange={(e) => handleSearch(e.target.value)} />
              <button type="button"><i className="fas fa-search"  /></button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

TableSearch.propTypes = {
  filterValues: PropTypes.array,
  onFilterChange: PropTypes.func,
  onSearch: PropTypes.func,
};

TableSearch.defaultProps = {
  filterValues: [],
  onFilterChange: undefined,
  onSearch: undefined,
};

export default TableSearch;

import api from "../utils/api";
import { buildFormData, notification } from "../utils/helpers";
import { setAuthToken } from "../utils/setAuthToken";

export const login = async (formData) => {
  try {
    let { data } = await api.post("/login", formData);
    notification(data.message);
    setAuthToken(data.token);
    return data;
  } catch (error) {
    notification(error.response.data.message, "error");
    console.log(error);
    throw new Error(error.response.data.message)

  }
};

export const loadUser = async () => {
  try {
    let { data } = await api.get("/account");
    console.log(data);
    return data.detail;
    
  } catch (error) {
    throw new Error(error.response.data.message)
  }
};

export const editProfile = async (formData) => {
  console.log(formData);
  try {
 
    let { data } = await api.post("/edit-profile", formData);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const changePassword = async (formData) => {
  try {
    let data = await api.post("/change-password", formData);
    console.log(data, "from service");
    return data;
  } catch (error) {
    throw new Error(error.response.data.message)
    console.log(error);
  }
};

export const logout = async () => {
  try {
    // let {data} = await axios.post('auth/user/logout',formData);

    localStorage.removeItem("token");
    notification("logout successfully");
    // setTimeout(() => window.location.reload(), 2000);
    // return data;
  } catch (error) {
    notification(error.response.data.message, "error");
    console.log(error);
  }
};

import "./App.css";
import Router from "./routes/Router";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAccessToken, setAuthToken } from "./utils/setAuthToken";
import { loadUser } from "./services/auth";
import { setAuth } from "./store/actions/auth";
import store from "./store/store";
import ConfirmPopup from "./Components/popups/ConfirmPopup";
// import event from "./utils/event";
import SuccessPopup from "./Components/popups/SuccessPopup";
import { updateSuccessPopup } from "./store/actions/alert"
import event from "./utils/event";
function App() {
  
  const { successPopup, success_popup_params: successPopupParams } = useSelector((state) => state.alert);

  const [confirmPopupParams, setConfirmPopupParams] = useState({
    visibility: false,
  });

  const showConfirmPopupHandler = (event) => {
    setConfirmPopupParams(event.detail);
  };

  const handler = (callback) => {
    if (confirmPopupParams[callback]) confirmPopupParams[callback]();

    setConfirmPopupParams({ visibility: false });
  };

  
  let dispatch = useDispatch();

  const data = async () => {
    if (getAccessToken) {
      setAuthToken(localStorage.token);
      store.dispatch(loadUser);
      let data = await loadUser();
      console.log(data)
      dispatch(setAuth(data));
      console.log(data,"data");
    }
  };



  useEffect(() => {
    event.subscribe("showConfirmPopup", showConfirmPopupHandler);
    return () => event.unsubscribe("showConfirmPopup", showConfirmPopupHandler);
  }, []);
  useEffect(() => {
    data();
  }, [localStorage?.token]);

  return (
    <div className="App">
      <Router />
      <ConfirmPopup
        active={confirmPopupParams?.visibility}
        onConfirm={() => handler("onConfirm")}
        onCancel={() => handler("onCancel")}
        message={confirmPopupParams?.message}
      />
   <SuccessPopup
        isError={successPopupParams.isError}
        active={successPopup}
        closed={() => dispatch(updateSuccessPopup(false, {}))}
        title={successPopupParams.title}
        message={successPopupParams.message}
        delay={successPopupParams.delay}
      ></SuccessPopup>
      {/* <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      /> */}
  
    </div>
  );
}

export default App;
import api from "../utils/api";

export const getSubscription = async (params) => {
  try {
    let { data } = await api.get(`/subscriptionLogs`, { params });
    console.log("subscriptionLogs console", data.detail);
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
};

export const viewPlan = async (id) => {
  try {
    let { data } = await api.get(`/view-plan/${id}`);
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
};

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { alertNotification } from "../services/notification";
import moment from "moment";
import { logout } from "../services/auth";
import useMessagePopup from "../Hooks/useMessagePopup";

export const Navbar = () => {
  const [notification, setNotification] = useState({});
  let { confirmPopup, errorPopup } = useMessagePopup();

  let { user } = useSelector((state) => state.auth);
  const fetch = async () => {
    try {
      let data = await alertNotification();
      setNotification(data?.detail);
    } catch (error) {}
  };

  const userLogout = async () => {
    confirmPopup({
      message: "Are you sure you want to logout?",

      onConfirm: async () => {
        try {
          let data = await logout();
          console.log(data);
        } catch (error) {
          console.log(error);
          errorPopup({ error });
        }
      },
    });
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <nav className="header-navbar navbar-expand-md navbar navbar-with-menu fixed-top navbar-light navbar-border">
        <div className="navbar-wrapper">
          <div className="navbar-header">
            <ul className="nav navbar-nav flex-row">
              <li className="nav-item mobile-menu d-md-none mr-auto">
                <a
                  className="nav-link nav-menu-main menu-toggle hidden-xs"
                  href="#"
                >
                  <i className="ft-menu font-large-1" />
                </a>
              </li>
              <li className="nav-item">
                {" "}
                <Link className="navbar-brand" to={"/"}>
                  <img
                    className="brand-logo img-fluid"
                    alt="stack admin logo"
                    src="images/loginLogo.png"
                  />{" "}
                </Link>{" "}
              </li>
              <li className="nav-item d-md-none">
                <a
                  className="nav-link open-navbar-container"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbar-mobile"
                >
                  <i className="fa fa-ellipsis-v" />
                </a>{" "}
              </li>
            </ul>
          </div>
          <div className="navbar-container content">
            <div className="collapse navbar-collapse" id="navbar-mobile">
              <ul className="nav navbar-nav me-auto float-start"></ul>
              <ul className="nav navbar-nav float-end nav-right align-items-center">
                <li className="dropdown dropdown-notification nav-item">
                  <a
                    className="nav-link nav-link-label"
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-expanded="true"
                  >
                    <i className="far fa-bell" />{" "}
                    <span className="badge badge-pill badge-default badge-danger badge-default badge-up">
                      {/* {notification && notification?.notiCount} */}
                      {notification &&
                        notification?.data?.length > 0 &&
                        notification?.data?.slice(0, 5)?.length}
                    </span>{" "}
                  </a>
                  <ul className="dropdown-menu dropdown-menu-right">
                    <li className="dropdown-menu-header">
                      <h6
                        className="d-flex dropdown-header justify-content-between m-0"
                        usersfuse
                      >
                        <span className="grey darken-2">Notifications</span>
                        {/* <span className="notification-tag badge badge-default badge-danger float-right m-0">
                          5 New
                        </span> */}
                      </h6>
                    </li>
                    {/* //notifications start from here */}
                    {notification !== undefined &&
                    notification?.data?.length > 0 ? (
                      notification?.data?.slice(0, 5)?.map((noti, index) => (
                        <>
                          <li
                            className="scrollable-container media-list ps-container ps-theme-dark"
                            key={index}
                          >
                            <a href="javascript:void(0)">
                              <div className="media d-flex">
                                <div className="media-left flex-shrink-0 align-self-top">
                                  <i className="far fa-bell" />
                                </div>
                                <div className="media-body flex-grow-1">
                                  {/* <h5 className="text-black">
                                        Lorem ipsum dolor sit
                                      </h5> */}
                                  <h6 className="media-heading">
                                    {noti && noti?.message}
                                  </h6>
                                  <small>
                                    <time
                                      className="media-meta"
                                      dateTime="2015-06-11T18:29:20+08:00"
                                    >
                                      {/* {moment(noti?.createdAt).fromNow()}{" "} */}
                                      {moment(noti?.createdAt).format(
                                        "DD/MM/YYYY h:mm a"
                                      )}
                                    </time>
                                  </small>
                                </div>
                              </div>
                            </a>
                          </li>
                        </>
                      ))
                    ) : (
                      <>
                        <li
                          className="scrollable-container media-list ps-container ps-theme-dark"
                          key="no-record"
                        >
                          <a href="javascript:void(0)">
                            <div className="media d-flex">
                              <div>
                                <h6 className="media-heading">
                                  No notification available
                                </h6>
                              </div>
                            </div>
                          </a>
                        </li>
                      </>
                    )}

                    <li className="dropdown-menu-footer">
                      <Link
                        className="dropdown-item text-center"
                        to="/notifications"
                      >
                        View All Notifications
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown dropdown-user nav-item">
                  <a
                    className="dropdown-toggle nav-link dropdown-user-link"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    <span className="avatar avatar-online">
                      <img
                        src={
                          user &&
                          (user.image_url.includes("undefined")
                            ? "images/myprofile_pic.png"
                            : user?.image_url)
                        }
                        alt="avatar"
                      />{" "}
                    </span>
                    <div className="user-details">
                      <div className="userName">
                        {user && user?.first_name + " " + user?.last_name}
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link className="dropdown-item" to="/profile">
                      <i className="fa fa-user" />
                      Profile
                    </Link>

                    <a
                      type="submit"
                      className="dropdown-item"
                      onClick={userLogout}
                    >
                      {" "}
                      <i class="fa fa-power-off"></i>Logout
                    </a>
                  </div>
                </li>
                {/* <li class="nav-item d-none d-md-block">
							<a class="nav-link nav-menu-main menu-toggle hidden-xs is-active" href="#"><i class="ft-menu"></i></a>
						</li> */}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

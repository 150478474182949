import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getNotification } from "../../services/notification";

export const Notification = () => {
  const [notifications, setNotifications] = useState({});

  const fetch = async () => {
    try {
      let data = await getNotification();
      setNotifications(data?.detail);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <section className="sec_noti box p-5" id="configuration">
      <div className="row mb-5">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="pageTitle text-capitalize m-0">Notification</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="noti_boxm">
            <ul className="noti_box">
              {notifications && notifications?.data?.length > 0 ? (
                notifications?.data?.map((noti, index) => (
                  <li key={index}>
                    <div className="d-flex">
                      <div className="noti_boxl mr-5">
                        <i className="fas fa-bell" />
                      </div>
                      <div className="noti_boxr">
                        {noti?.title}
                        <p className="mb-2">{noti?.message}</p>
                        <div className="d-flex">
                          <h6>
                            <strong>Date: </strong>
                            {moment(noti?.createdAt).format("DD/MM/YYYY")}
                          </h6>
                          <h6>
                            <strong>Time: </strong>{" "}
                            {moment(noti.createdAt).format("h:mm a")}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <li key="no-record-noti">
                  <div className="d-flex">
                    <div className="noti_boxr">
                      {/* {noti?.title} */}
                      <p className="mb-2">No notification available!</p>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
export const Profile = () => {
  let { user } = useSelector((state) => state.auth);

  console.log(user);
  return (
    <>
      <section className="myprofile box py-5" id="configuration">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="d-block d-md-flex justify-content-between mb-4 align-items-center">
              <h3 className="text-capitalize mb-0 h_20 text-bold-300">
                My Profile
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <form className="myprofile_main">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-5">
                    <div className="attached">
                      {user && (
                        <>
                          <img
                            src={
                              user?.image_url?.includes("undefined")
                                ? "images/myprofile_pic.png"
                                : user?.image_url
                            }
                            className="img-fluid rounded-circle"
                            alt=""
                            style={{ width: "150px", height: "150px" }}
                          />
                        </>
                      )}
                      {/* <button type="button" name="file" className="btn camera-btn">
                                                <i className="fas fa-camera" />
                                            </button>
                                            <input type="file" id="upload" name="file" /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <Link
                        to="/change-password"
                        className="changePassword text-purpleli text-underline"
                      >
                        Change Password
                      </Link>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-field">
                        <label htmlFor className="siteLabel px-0">
                          User Name<span className="text-danger">*</span>
                        </label>
                        <div className="position-relative">
                          <h6 className="text-secondary h_14 montserrat">
                            {user && user?.first_name + " " + user?.last_name}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-field">
                        <label htmlFor className="siteLabel px-0">
                          Contact Number<span className="text-danger">*</span>
                        </label>
                        <div className="position-relative">
                          <h6 className="text-secondary h_14 montserrat">
                            {user && user?.phone_number}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="form-field">
                        <label htmlFor className="siteLabel px-0">
                          Email<span className="text-danger">*</span>
                        </label>
                        <div className="position-relative">
                          <h6 className="text-secondary h_14 montserrat">
                            {user && user?.email}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-5">
                      <Link to="/edit-profile" className="btn_purple px-5">
                        Edit
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

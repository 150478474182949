
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import useFileReader from '../../Hooks/useFileReader'
import useMessagePopup from '../../Hooks/useMessagePopup'
import Validator from 'validatorjs'
import { loadUser } from '../../services/auth'
import { viewPlan, editPLan } from '../../services/plan';
import { useParams } from 'react-router-dom';

export const EditPlan = () => {

    let navigate = useNavigate()
    let { user } = useSelector(state => state.auth)
    const [updtaeUser, setUpdateUser] = useState(user)
    useEffect(() => {
        fetch()
    }, [])


    const [validation, setValidation] = useState({});
    // const [fakePath, setFakePath] = useState(null);
    const [thisImage, setThisImage] = useState({})
    let { getThumbnail } = useFileReader()
    const { successPopup, errorPopup } = useMessagePopup()


    const [formData, setFormData] = useState({
        first_name: null,
        last_name: null,
        image: '',
    })

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    let { id } = useParams()

    const fetch = async () => {

        try {
            let data = await viewPlan(id);
            setFormData(data?.detail)
        } catch (error) {
            console.log(error);
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        let validator = new Validator(formData, {
            name: 'required',
            amount: "required",
            interval: "required",
            description: "required",
        })
        setValidation(validator)
        if (validator.fails()) return;
        try {
            // let newData = new FormData()
            // newData.append('first_name', formData.first_name);
            // newData.append('last_name', formData.last_name);
            // newData.append('image', formData.image);
            // console.log(JSON.stringify(formData.image),67);
            let { status, message } = await editPLan(formData);
            if (status) {
                successPopup({ message })
                loadUser()
                setTimeout(() => {
                    navigate('/plans');
                }, 2000)
            }
        } catch (error) {
            console.log(error)
            errorPopup(error)
        }
    }

    return (
        <>
            <section className="myprofile box py-5" id="configuration">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="d-block d-md-flex justify-content-between mb-4 align-items-center">
                            <Link to='/plans'>
                                <h3 className="text-capitalize mb-0 h_20 text-bold-300"><i className="fas fa-arrow-left me-3 topMArrow" /> Edit Plan</h3>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        <form className="myprofile_main">
                            <div className="row justify-content-center">
                                <div className="form-field">
                                    <label htmlFor className="siteLabel ps-4 mb-2">Name<span className="text-danger">*</span></label>
                                    <div className="position-relative">
                                        <input type="text" className="siteInput" placeholder="Mark" name="name"
                                            value={formData?.name} id="name" onChange={onChange} />
                                    </div>
                                    <span className='pt-5 text-danger'>{validation?.errors?.first('name')}</span>

                                </div>
                                <div className="form-field">
                                    <label htmlFor className="siteLabel ps-4 mb-2">Amount($)<span className="text-danger">*</span></label>
                                    <div className="passwordWrapper position-relative">
                                        <input type="text" className="siteInput passInput" placeholder="Carson" id="amount" name="amount" value={formData?.amount}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <span className='pt-5 text-danger'>{validation?.errors?.first('amount')}</span>

                                </div>
                                <div className="form-field">
                                    <label htmlFor className="siteLabel ps-4 mb-2">Interval<span className="text-danger">*</span></label>
                                    <div className="passwordWrapper position-relative">
                                        <select className="siteInput passInput" value={formData.interval} name="interval" onChange={onChange} >
                                            <option value="day">Day</option>
                                            <option value="week">Week</option>
                                            <option value="month">Month</option>
                                            <option value="year">Year</option>
                                        </select>
                                        {/* <input type="text" className="siteInput passInput" placeholder="Carson" name="amount" value={formData?.amount}
                                            onChange={onChange}
                                        /> */}
                                    </div>
                                    <span className='pt-5 text-danger'>{validation?.errors?.first('interval')}</span>

                                </div>
                                <div className="form-field">
                                    <label htmlFor className="siteLabel ps-4 mb-2">Description<span className="text-danger">*</span></label>
                                    <div className="position-relative">
                                        <textarea rows="" cols="" type="text" className="siteInput" placeholder="Mark" name="description"
                                            value={formData?.description} id="description" onChange={onChange} >
                                        </textarea>
                                    </div>
                                    <span className='pt-5 text-danger'>{validation?.errors?.first('description')}</span>

                                </div>
                            </div>
                            <div className="button text-center mt-5">
                                <button type="sumbit" className=" btn btn-success  btn_darkbluep" onClick={onSubmit} >Update</button>
                                <Link to="/plans" className="btn btn-danger btn_whitebor ms-2">Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

        </>
    )
}

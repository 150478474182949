import api from "../utils/api";

export const getPlans = async (params) => {
  try {
    let { data } = await api.get(`/plans`, { params });
    console.log("Plans console", data.detail);
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
};

export const viewPlan = async (id) => {
  try {
    let { data } = await api.get(`/view-plan/${id}`);
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
};

export const editPLan = async (formData) => {
  console.log(formData);
  try {
    let { data } = await api.post(`/update-plan/${formData._id}`, formData);
    return data;
  } catch (error) {
    console.log(error);
  }
};


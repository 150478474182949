import React, { useCallback, useState } from "react";
import useMessagePopup from "../../Hooks/useMessagePopup";
import Validator from "validatorjs";
import { login } from "../../services/auth";
import { Link } from "react-router-dom";

const Login = () => {
  const [formData, setFormData] = useState({
    email: null,
    password: null,
  });

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const [validation, setValidation] = useState({});

  const [value, setValue] = useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = (e) => {
    console.log(value);
    setValue({ ...value, showPassword: !value.showPassword });
  };
  const { successPopup, errorPopup } = useMessagePopup();

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const validator = new Validator(formData, {
      email: "required|email",
      password: "required",
    });
    setValidation(validator);
    if (validator.fails()) return;

    try {
      let { status, message } = await login(formData);
      if (status) {
        successPopup({ message: "Logged In Successfully" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      errorPopup(error);
      console.log(error);
    }
  });

  return (
    <>
      <section className="loginPage">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="loginBgRight">
                <div className="loginCard">
                  <div className="">
                    <img
                      src="images/loginLogo.png"
                      alt=""
                      className="loginLogo img-fluid mb-2"
                    />
                  </div>
                  <div className="formBox">
                    <div className="formHeading my-4">
                      <h2>Welcome to Our SKS QUOTES</h2>
                      {/* <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p> */}
                    </div>
                    <form className="py-2" onSubmit={onSubmit}>
                      <div className="form-field">
                        <label htmlFor className="siteLabel">
                          Email Address<span className="text-danger">*</span>
                        </label>
                        <div className="position-relative">
                          <input
                            type="email"
                            className="siteInput"
                            placeholder="username@mail.com"
                            name="email"
                            value={formData.email}
                            onChange={onChange}
                            id
                          />
                        </div>
                        <span className="pt-5 text-danger">
                          {validation?.errors?.first("email")}
                        </span>
                      </div>
                      <div className="form-field">
                        <label htmlFor className="siteLabel">
                          Password<span className="text-danger">*</span>
                        </label>
                        <div className="passwordWrapper position-relative">
                          <input
                            type={value.showPassword ? "text" : "password"}
                            className="siteInput passInput"
                            placeholder="********************"
                            name="password"
                            onChange={onChange}
                            value={formData.password}
                            id
                          />
                          <button
                            type="button"
                            className="passDisplay"
                            onClick={handleClickShowPassword}
                          >
                            <i
                              className="fas fa-eye-slash"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                        <span className="pt-5 text-danger">
                          {validation?.errors?.first("password")}
                        </span>
                      </div>
                      <div className="d-lg-flex d-block justify-content-between">
                        <div className="d-flex align-items-center">
                          <input type="checkbox" id="rme" name="rme" />
                          <label htmlFor="rme" className="ps-4">
                            Remember Me
                          </label>
                        </div>
                        {/* <div className="text-end">
                          <Link className="forgotLink" to="/verify-email">
                            Forgot Password?
                          </Link>
                        </div> */}
                      </div>
                      <div className="form-field mt-3 mt-lg-4 mb-0 text-center">
                        <button type="submit" className="btn_purple w-100 mb-3">
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-4 d-none d-lg-block">
              <div className="loginBgLeft">
                <img
                  src="images/loginLeftImage.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;

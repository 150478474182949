import React from 'react'
import { Link } from 'react-router-dom'

export const PR3 = () => {
  return (
    <>
    <section className="loginPage">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="loginBgRight">
            <div className="loginCard">
              <div className>
                <img src="../../images/loginLogo.png" alt="" className="loginLogo img-fluid mb-2" />
              </div>
              <div className="formBox">
                <div className="formHeading my-4">
                  <h2>Forgot Password</h2>
                  <p>Type in your new Password</p>
                </div>
                <form action="login.php" method="POST" className="py-2">
                  <div className="form-field">
                    <label htmlFor className="siteLabel">New Password<span className="text-danger">*</span></label>
                    <div className="passwordWrapper position-relative">
                      <input type="password" className="siteInput passInput" placeholder="Enter New Password" name id />
                      <button type="button" className="passDisplay">
                        <i className="fas fa-eye-slash" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="form-field">
                    <label htmlFor className="siteLabel">Confirm Password<span className="text-danger">*</span></label>
                    <div className="passwordWrapper position-relative">
                      <input type="password" className="siteInput passInput" placeholder="Enter Confirm Password" name id />
                      <button type="button" className="passDisplay">
                        <i className="fas fa-eye-slash" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="form-field mt-3 mt-lg-4 mb-0 text-center">
                    <button type="submit" className="btn_purple w-100 mb-3">Continue</button>
                    <Link to="/login" className="siteBtn2 w-100 mb-3">Back To Website</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>            
        <div className="col-lg-6 px-4 d-none d-lg-block">
          <div className="loginBgLeft">
            <img src="../../images/loginLeftImage.png" alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
  )
}

import api from "../utils/api";

export const getQuries=async(params)=>{
    try {
        let { data } = await api.get(`/contact-us`,{params});
        console.log(data);
        return data;
      } catch (error) {
        console.log(error);
        throw new Error(error.response.data.message)
      }
}


export const viewQuries=async(id)=>{
  try {
      let {data} = await api.get(`/view-query/${id}`);
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
      throw new Error(error.response.data.message)
    }
}



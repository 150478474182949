
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import useFileReader from '../../Hooks/useFileReader'
import useMessagePopup from '../../Hooks/useMessagePopup'
import Validator from 'validatorjs'
import { editProfile, loadUser } from '../../services/auth'

export const EditProfile = () => {
    let navigate=useNavigate()
    let { user } = useSelector(state => state.auth)
    const [updtaeUser,setUpdateUser]= useState(user)
    useEffect(() => {

        if (user) {
            // setFormData({
            //     first_name: user?.first_name,
            //     last_name: user?.last_name,

            // })

            if (!user?.image_url.includes('undefined')) {
                setThisImage(user?.image_url)
            }
            else {
                setThisImage("images/myprofile_pic.png")
            }
        }

    }, [user])


    const [validation, setValidation] = useState({});
    // const [fakePath, setFakePath] = useState(null);
    const [thisImage, setThisImage] = useState({})
    let { getThumbnail } = useFileReader()
    const { successPopup ,errorPopup} = useMessagePopup()


    const [formData, setFormData] = useState({
        first_name: null,
        last_name: null,
        image: '',   
     })

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSetImage = async (e) => {
        let file = e.target.files[0];
        console.log(file,"file")
        let fileThumbnail = await getThumbnail(file);
        setFormData({...formData,image:file });
        setThisImage(fileThumbnail);
        
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        let validator = new Validator(formData, {
            first_name: 'required',
            last_name:"required",
        })
        setValidation(validator)
        if (validator.fails()) return;

        try {
            let newData = new FormData()
            newData.append('first_name', formData.first_name);
            newData.append('last_name', formData.last_name);
            newData.append('image', formData.image);
            // console.log(JSON.stringify(formData.image),67);
            let { status, message } = await editProfile(newData);
            if (status) {
                successPopup({ message })
                setFormData({...newData,user})
                loadUser()
                //    setTimeout(()=>{
            //     navigate('/profile');
            //    },2000)
            }
        } catch (error) {
            console.log(error)
            errorPopup(error)
        }
    }

    return (
        <>
            <section className="myprofile box py-5" id="configuration">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="d-block d-md-flex justify-content-between mb-4 align-items-center">
                            <Link to='/profile'>
                                <h3 className="text-capitalize mb-0 h_20 text-bold-300"><i className="fas fa-arrow-left me-3 topMArrow" /> Profile</h3>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        <form className="myprofile_main">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-5 text-center">
                                        <div className="attached">
                                            {/* <img src="../../images/myprofile_pic.png" className="img-fluid ml-0" alt="" /> */}

                                            {user && (
                                                user?.image_url.includes("undefined") ? (
                                                    <img className="img-fluid ml-0 rounded-circle" alt="admin-avatar"
                                                        src={thisImage}
                                                        style={{ width: "150px", height: "150px" }}
                                                    />
                                                ) : (
                                                    <img className="img-fluid ml-0 rounded-circle" alt="admin-avatar"
                                                        src={thisImage}
                                                        style={{ width: "150px", height: "150px" }}
                                                    />
                                                )
                                            )
                                            }

                                            <label type="button" htmlFor='upload' className="btn camera-btn">
                                                <i className="fas fa-camera" />
                                            </label>
                                            <input type="file" id="upload"
                                                onChange={(e) => onSetImage(e)}
                                                accept="image/png, image/jpeg, image/svg" />
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <Link to="/change-password" className="changePassword ml-4 pl-3 pr-3 d-block">Change Password</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="form-field">
                                    <label htmlFor className="siteLabel ps-4 mb-2">First Name<span className="text-danger">*</span></label>
                                    <div className="position-relative">
                                        <input type="text" className="siteInput" placeholder="Mark" name="first_name"
                                            value={formData?.first_name} id onChange={onChange} />
                                    </div>
                                    <span className='pt-5 text-danger'>{validation?.errors?.first('first_name')}</span>

                                </div>
                                <div className="form-field">
                                    <label htmlFor className="siteLabel ps-4 mb-2">Last Name<span className="text-danger">*</span></label>
                                    <div className="passwordWrapper position-relative">
                                        <input type="text" className="siteInput passInput" placeholder="Carson" name="last_name" value={formData?.last_name}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <span className='pt-5 text-danger'>{validation?.errors?.first('last_name')}</span>

                                </div>
                                <div className="col-md-12">
                                    <div className="felid">
                                        <label className="h_14">Email Address</label>
                                        <p className="h_16">
                                            {user && (
                                                user?.email
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="button text-center mt-5">
                                <button type="sumbit" className=" btn btn-success  btn_darkbluep" onClick={onSubmit} >Update</button>
                                <Link to="/profile" className="btn btn-danger btn_whitebor ms-2">Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

        </>
    )
}

import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Link } from 'react-router-dom';
import Table from '../../Components/Table';
import { getQuries } from '../../services/queries';
import { format_date } from '../../utils/helpers';
import { debounce } from "lodash";
import { getPlans } from '../../services/plan';

const fields = [
  {
    label: "Name",
    key: "name"
  },
  {
    label: "amount",
    key: "amount"

  },
  {
    label: "interval",
    key: "interval"

  },
  {
    label: "Date",
    key: "createdAt",
    format: format_date
  },
]
export const PlansListing = () => {

  const handleSearch = debounce((value) => {


  }, 500);
  const [query, setQueries] = useState({});
  const [filterValues, setFilterValues] = useState({});

  const fetch = async (page = 1) => {
    try {
      let data = await getPlans({ page, ...filterValues });
      setQueries(data?.detail);
    } catch (error) {

    }
  }

  useEffect(() => {
    fetch()
  }, [filterValues?.searchString])
  return (
    <>
      <section id="configuration">
        <div className="row">
          <div className="col-12">
            <div className="card-content collapse show dashCard py-5 px-5">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <h1 className="pageTitle text-capitalize m-0">Plans</h1>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  {/* <div className="d-flex align-items-center">
                    <label htmlFor className="siteLabel p-0 pe-3">Sort by:</label>
                    <div className="position-relative">
                      <select className="w-300">
                        <option>Quote Title</option>
                        <option>Quote Title</option>
                        <option>Quote Title</option>
                      </select>
                    </div>
                  </div> */}
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-center justify-content-end">
                    <form className="serchbarHead w-300">
                      <input type="=text" name placeholder="Search...." onChange={(e) => (setFilterValues({ ...filterValues, searchString: e.target.value }))} />
                      <button type="button"><i className="fas fa-search" /></button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <Table
                    fields={fields}
                    data={query}
                    hasPagination={true}
                    entrieShow=""
                    extraHeads={
                      () => (
                        <>
                          <th>Actions</th>
                        </>
                      )
                    }
                    extraCells={(item) => (

                      <><td>
                        <Link to={`/edit-plan/${item._id}`}> Edit</Link>
                      </td></>
                    )}


                  />

                </div>
              </div>
              {/* <div className="row dataTablesInfoMain">
                <div className="col-sm-12 col-md-5 align-self-center">
                  <div className="dataTables_info">Showing 05 Out Of 50 Entries</div>
                </div>
                <div className="col-sm-12 col-md-7">
                  <div className="dataTables_paginate">
                    <ul className="pagination justify-content-end mb-0">
                      <li className="paginate_button page-item previous disabled"><a href="#" className="page-link">Previous</a></li>
                      <li className="paginate_button page-item active"><a href="#" className="page-link">1</a></li>
                      <li className="paginate_button page-item"><a href="#" className="page-link">2</a></li>
                      <li className="paginate_button page-item"><a href="#" className="page-link">3</a></li>
                      <li className="paginate_button page-item"><a href="#" className="page-link">4</a></li>
                      <li className="paginate_button page-item"><a href="#" className="page-link">5</a></li>
                      <li className="paginate_button page-item next disabled" i><a href="#" className="page-link">Next</a></li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

    </>)
}

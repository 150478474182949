import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Link } from 'react-router-dom';
import Table from '../../Components/Table';
import { getQuries } from '../../services/queries';
import { format_date } from '../../utils/helpers';
import { debounce } from "lodash";
import { getSubscription } from '../../services/subscription';

const fields = [

]
export const SubscriptionLogsListing = () => {

  const handleSearch = debounce((value) => {


  }, 500);
  const [query, setQueries] = useState({});
  const [filterValues, setFilterValues] = useState({});

  const fetch = async (page = 1) => {
    try {
      console.log("page", page);
      let data = await getSubscription({ page, ...filterValues });
      setQueries(data?.detail);
    } catch (error) {

    }
  }
  const handleDataFromChild = (data) => {
    fetch(data)
  };

  useEffect(() => {
    fetch()
  }, [filterValues?.searchString])
  return (
    <>
      <section id="configuration">
        <div className="row">
          <div className="col-12">
            <div className="card-content collapse show dashCard py-5 px-5">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <h1 className="pageTitle text-capitalize m-0">Subscription Logs</h1>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-center justify-content-end">
                    <form className="serchbarHead w-300">
                      <input type="=text" name placeholder="Search...." onChange={(e) => (setFilterValues({ ...filterValues, searchString: e.target.value }))} />
                      <button type="button"><i className="fas fa-search" /></button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <Table
                    fields={fields}
                    data={query}
                    hasPagination={true}
                    onDataReceived={handleDataFromChild}
                    extraHeads={
                      () => (
                        <>

                          <th>Name</th>

                          <th>Date</th>
                        </>
                      )
                    }
                    extraCells={(item) => (

                      <>
                        <td>
                          {item?.user?.first_name + " " + item?.user?.last_name}
                        </td>

                        <td>
                          {format_date(item.createdAt)}
                        </td>
                      </>
                    )}


                  />

                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

    </>)
}

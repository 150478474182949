import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { viewQuries } from '../../services/queries';

export const QueryDetail = () => {

    const [query, setQuery] = useState({});

    let {id}=useParams()
    console.log(query,8);
    const fetch = async () => {

        try {
            let data = await viewQuries(id);
            setQuery(data?.detail)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetch()
    }, [])


    return (

        <>
            <section id="configuration">
                <div className="row">
                    <div className="col-12">
                        <div className="card-content collapse show dashCard py-5 px-5">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h1 className="pageTitle text-capitalize m-0"><i className="fas fa-long-arrow-left" onclick="javascript:history.go(-1)" /> Date</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row mb-3">
                                        <div className="col-md-5">
                                            <div className="form-field">
                                                <label htmlFor className="siteLabel px-0">Name<span className="text-danger">*</span></label>
                                                <div className="position-relative">
                                                    <h6 className="text-secondary h_14 montserrat">{query && (query.username)}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-5">
                                            <div className="form-field">
                                                <label htmlFor className="siteLabel px-0">Email<span className="text-danger">*</span></label>
                                                <div className="position-relative">
                                                    <h6 className="text-secondary h_14 montserrat">{query && (query.email)}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-5">
                                            <div className="form-field">
                                                <label htmlFor className="siteLabel px-0">Subject<span className="text-danger">*</span></label>
                                                <div className="position-relative">
                                                    <h6 className="text-secondary h_14 montserrat">{query && (query.email)}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-5">
                                            <div className="form-field">
                                                <label htmlFor className="siteLabel px-0">Message<span className="text-danger">*</span></label>
                                                <div className="position-relative">
                                                    <h6 className="text-secondary h_14 montserrat">{query && (query.message)}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

import React, { useState } from 'react'
import useMessagePopup from '../../Hooks/useMessagePopup';
import { changePassword } from '../../services/auth';
import Validator from 'validatorjs';
export const ChangePassword = () => {

    const [validation, setValidation] = useState({});
    const [formData, setFormData] = useState({
        current_password: null,
        password: null,
        password_confirmation: null
    })

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const [value, setValue] = useState({
        current_password: "",
        showCurrentPassword: false
    })
    const [passwordValue, setPasswordValue] = useState({
        password: "",
        showPassword: false
    })
    const [passwordConfirmationValue, setPasswordConfirmationValue] = useState({
        passwordConfirmation: "",
        showPasswordConfirmation: false
    })


    const handleCurrentPassword = (e) => {
        setValue({ ...value, showCurrentPassword: !value.showCurrentPassword })
    }

    const handlePassword = (e) => {
        setPasswordValue({ ...passwordValue, showPassword: !passwordValue.showPassword })
    }
    const handleConfirmationPassword = (e) => {
        setPasswordConfirmationValue({ ...passwordConfirmationValue, showPasswordConfirmation: !passwordConfirmationValue.showPasswordConfirmation })
    }
    const { successPopup, errorPopup } = useMessagePopup();

    const onSubmit = async (e) => {
        e.preventDefault()
        let validator = new Validator(formData, {
            current_password: 'required',
            password: 'required|confirmed',
            password_confirmation: 'required'
        })
        setValidation(validator)
        if (validator.fails()) return;

        try {
            let { status, message } = await changePassword(formData);
            console.log(message);
            console.log(formData?.current_password)
            if (status) {
                successPopup({ message })
            }
            else {
                errorPopup(message)
            }
        } catch (error) {
            console.log(error)
            errorPopup(error)
        }
    }
    return (
        <>
            <section className="myprofile box py-5" id="configuration">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="d-block d-md-flex justify-content-between mb-4 align-items-center">
                            <h3 className="text-capitalize mb-0 h_20 text-bold-300"><i className="fas fa-arrow-left me-3 topMArrow" onclick="javascript:history.go(-1)" /> Change Password</h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        <form className="myprofile_main"
                        // onSubmit={onSubmit}
                        >
                            <div className="row justify-content-center">
                                <div className="col-md-12 mr-5">
                                    <div className="form-field">
                                        <label htmlFor className="siteLabel ps-4 mb-2">Current  Password<span className="text-danger">*</span></label>
                                        <div className="passwordWrapper position-relative">
                                            <input type={value.showCurrentPassword ? "text" : "password"} className="siteInput passInput" placeholder="Enter Current  Password" name="current_password"
                                                value={formData?.current_password} onChange={onChange}
                                                id />
                                            <button type="button" className="passDisplay" onClick={handleCurrentPassword}>
                                                <i className="fas fa-eye-slash" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <span className='pt-5 text-danger'>{validation?.errors?.first('current_password')}</span>

                                    </div>
                                    <div className="form-field">
                                        <label htmlFor className="siteLabel ps-4 mb-2">New Password<span className="text-danger">*</span></label>
                                        <div className="passwordWrapper position-relative">
                                            <input type={passwordValue.showPassword ? "text" : "password"} className="siteInput passInput"
                                                placeholder="Enter New Password" name="password"
                                                value={formData?.password} onChange={onChange} id />
                                            <button type="button" className="passDisplay" onClick={handlePassword}>
                                                <i className="fas fa-eye-slash" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <span className='pt-5 text-danger'>{validation?.errors?.first('password')}</span>
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor className="siteLabel ps-4 mb-2">Confirm Password<span className="text-danger">*</span></label>
                                        <div className="passwordWrapper position-relative">
                                            <input type={passwordConfirmationValue.showPasswordConfirmation ? "text" : "password"} className="siteInput passInput" placeholder="Confirm Password" name="password_confirmation"
                                                value={formData?.password_confirmation} onChange={onChange}
                                                id />
                                            <button type="button" className="passDisplay" onClick={handleConfirmationPassword}>
                                                <i className="fas fa-eye-slash" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <span className='pt-5 text-danger'>{validation?.errors?.first('password_confirmation')}</span>

                                    </div>
                                </div>
                                <div className="button text-center mt-5">
                                    <button type='submit' className="btn_purple
                                    pl-5 pr-5" onClick={onSubmit}>Update</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

        </>)
}

export const SidebarItem = [
  // {
  //   title: 'Quote Management',
  //   path: '/',
  //   icon: 'fal fa-th-large',
  //   cName: 'nav-item'
  // },
  // {
  //   title: 'Category Management',
  //   icon: 'fal fa-comments-alt',
  //   cName: 'nav-item',
  //   path: '/category',
  // },
  {
    title: 'Queries',
    icon: 'fal fa-comments-alt',
    cName: 'nav-item',
    path: '/queries',
  },
  {
    title: 'Plans',
    icon: 'fal fa-comments-alt',
    cName: 'nav-item',
    path: '/plans',
  },
  {
    title: 'Subscription Logs',
    icon: 'fal fa-comments-alt',
    cName: 'nav-item',
    path: '/subscriptions',
  },
]
import React from "react";
import { Route, Routes } from "react-router-dom";
import { ChangePassword } from "../Pages/Account/ChangePassword";
import { EditProfile } from "../Pages/Account/EditProfile";
import { Profile } from "../Pages/Account/Profile";
import Login from "../Pages/Auth/Login";
import { Add } from "../Pages/Dashboard/Add";
import { EditQuote } from "../Pages/Dashboard/EditQuote";
import { QuoteManagement } from "../Pages/Dashboard/QuoteManagement";
import { ViewQuote } from "../Pages/Dashboard/ViewQuote";
import { PR1 } from "../Pages/PasswordRecovery/PR1";
import { PR2 } from "../Pages/PasswordRecovery/PR2";
import { PR3 } from "../Pages/PasswordRecovery/PR3";
import { QueriesListing } from "../Pages/Quries/QueriesListing";
import { PlansListing } from "../Pages/Plans/PlansListing";
import { EditPlan } from "../Pages/Plans/EditPlan";
import { SubscriptionLogsListing } from "../Pages/Subscription/SubscriptionLogsListing";

import { QueryDetail } from "../Pages/Quries/QueryDetail";
import { Notification } from "../Pages/notifications/Notification";
//
import AuthGuard from "./AuthGuard";
import PrivateRoutes from "./PrivateRoutes";
import { Listing } from "../Pages/Category/Listing";
import { Edit } from "../Pages/Category/Edit";
import { AddCategory } from "../Pages/Category/AddCat";

export default function Router() {
  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <AuthGuard>
              {" "}
              <Login Login />
            </AuthGuard>
          }
        />

        <Route
          path="/verify-email"
          element={
            <AuthGuard>
              {" "}
              <PR1 />
            </AuthGuard>
          }
        />
        <Route
          path="/verify-code"
          element={
            <AuthGuard>
              {" "}
              <PR2 />
            </AuthGuard>
          }
        />
        <Route
          path="/password-reset"
          element={
            <AuthGuard>
              {" "}
              <PR3 />
            </AuthGuard>
          }
        />

        <Route
          path="/"
          element={
            <PrivateRoutes>
              <QueriesListing />
            </PrivateRoutes>
          }
        ></Route>
        <Route
          path="/profile"
          element={
            <PrivateRoutes>
              <Profile />
            </PrivateRoutes>
          }
        ></Route>
        <Route
          path="/edit-profile"
          element={
            <PrivateRoutes>
              <EditProfile />
            </PrivateRoutes>
          }
        ></Route>
        <Route
          path="/change-password"
          element={
            <PrivateRoutes>
              <ChangePassword />
            </PrivateRoutes>
          }
        ></Route>

        {/* <Route path='/' element={<PrivateRoutes><QuoteManagement /></PrivateRoutes>}></Route>
                <Route path='/add-quotes' element={<PrivateRoutes><Add /></PrivateRoutes>}></Route>
                <Route path='/edit-quotes/:id' element={<PrivateRoutes><EditQuote /></PrivateRoutes>}></Route>
                <Route path='/view-quotes/:id' element={<PrivateRoutes><ViewQuote /></PrivateRoutes>}></Route> */}

        <Route
          path="/queries"
          element={
            <PrivateRoutes>
              <QueriesListing />
            </PrivateRoutes>
          }
        ></Route>
        <Route
          path="/view-query/:id"
          element={
            <PrivateRoutes>
              <QueryDetail />
            </PrivateRoutes>
          }
        ></Route>
        <Route
          path="/plans"
          element={
            <PrivateRoutes>
              <PlansListing />
            </PrivateRoutes>
          }
        ></Route>
        <Route
          path="/edit-plan/:id"
          element={
            <PrivateRoutes>
              <EditPlan />
            </PrivateRoutes>
          }
        ></Route>
        <Route
          path="/subscriptions"
          element={
            <PrivateRoutes>
              <SubscriptionLogsListing />
            </PrivateRoutes>
          }
        ></Route>
        <Route
          path="/notifications"
          element={
            <PrivateRoutes>
              <Notification />
            </PrivateRoutes>
          }
        ></Route>

        {/* <Route path='/category' element={<PrivateRoutes><Listing /></PrivateRoutes>}></Route>
                <Route path='/add-category' element={<PrivateRoutes><AddCategory /></PrivateRoutes>}></Route>
                <Route path='/edit-category/:id' element={<PrivateRoutes><Edit /></PrivateRoutes>}></Route> */}

        {/* <Route path='/profile' element={<PrivateRoutes><Profile/></PrivateRoutes>}></Route>
                <Route path='/edit-profile' element={<PrivateRoutes><EditProfile/></PrivateRoutes>}></Route>
                <Route path='/change-password' element={<PrivateRoutes><ChangePassword/></PrivateRoutes>}></Route>
                
                <Route path='/users' element={<PrivateRoutes><Users/></PrivateRoutes>}></Route>
                <Route path='/view-user/:id' element={<PrivateRoutes><ViewUser/></PrivateRoutes>}></Route>

                <Route path='/feedback' element={<PrivateRoutes><Feedback/></PrivateRoutes>}></Route>
                <Route path='/view-feedback/:id' element={<PrivateRoutes><ViewFeedback/></PrivateRoutes>}></Route>

                <Route path='/push-notification' element={<PrivateRoutes><PushNotification/></PrivateRoutes>}></Route>
                <Route path='/notifications' element={<PrivateRoutes><Notifications/></PrivateRoutes>}></Route>
                <Route path='/error404' element={<PrivateRoutes><Error404/></PrivateRoutes>}></Route> */}
      </Routes>
    </>
  );
}

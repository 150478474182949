const { default: api } = require("../utils/api");


export const getNotification= async()=>{

    try {

        let {data}=await api.get('/notifications');
        return data
    } catch (error) {
        console.log(error);
        throw new Error(error.response.data.messgae)
    }

}


export const alertNotification= async()=>{

    try {

        let {data}=await api.get('/alert-notification');
        return data
    } catch (error) {
        console.log(error);
        throw new Error(error.response.data.messgae)
    }

}
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { SidebarItem } from './SidebarItems'

export const Sidebar = () => {

  let [active, setActive] = useState(null)
  let path = window.location.pathname
  console.log("path", path);
  useEffect(() => {

    if (path === "/rayshaun/admin") {
      setActive('Quote Management')
    }
    else if (path === "/rayshaun/admin/queries") {
      setActive('Queries')
    }

  }, [path])
  return (
    <>
      <div className="main-menu menu-fixed menu-light menu-accordion" data-scroll-to-active="true">
        <div className="main-menu-content">
          <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
            {
              SidebarItem && (
                SidebarItem.map((item, index) => (
                  <li className={`nav-item ${path === item.path ? "active" : ""}`} key={index}>
                    <Link to={item.path}><i className={item.icon} />
                      <span className="menu-title" data-i18n>{item?.title}</span></Link>
                  </li>
                ))
              )
            }
          </ul>

        </div>
      </div>
    </>
  )
}
